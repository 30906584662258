.inputField{
    height: 8vh;
    position: relative;
}

.inputField input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 4px solid #9A6735;
    background: transparent;
    padding-left: 15%;
    color: #9A6735;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    font-size: 18px;
    
}

.inputField input::placeholder {
    color: #9A6735;
    opacity: 1;
}
  
.inputField input::-ms-input-placeholder {
    color: #9A6735;
}

.inputField input:focus {
    outline: none;
}

.inputField-img{
    pointer-events: none;
    position: absolute;
    left: 2.5%;
    width: 10%;
    top: 50%;
    transform: translateY(-50%);
    max-height: 50%;
}

.inputField-locked{
    height: 8vh;
    position: relative;
}

.inputField-locked input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: 4px solid #C0C0C0;
    background: transparent;
    padding-left: 15%;
    color: #9A6735;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 15px;
    font-size: 18px;
    
}

.inputField-locked input::placeholder {
    color: #9A6735;
    opacity: 1;
}
  
.inputField-locked input::-ms-input-placeholder {
    color: #9A6735;
}

.inputField-locked input:focus {
    outline: none;
}

@media (max-width: 450px) {
    .inputField{
        height: 6vh;
    }

    .inputField-locked{
        height: 6vh;
    }
}