
.landing-body-1{
    background-image: url("../../../Images/PlantPal\ Mockup.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 50%;
}

.landing-body-1-section{
    width: 80%;
    height: 100%;
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.landing-body-1-content{
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    background-color: rgba(230, 240, 220, 0.9);
    padding: 2%;
}

.landing-body-1-content h1{
    color: #55883B;
    padding: 0;
    margin: 0;
}

.landing-body-1-content p{
    width: 100%;
}

.landing-body-1-img{
    width: 50%;
    position: relative
}

.landing-body-1-img img{
    width: auto;
    height: 90%;
    position: absolute;
    bottom: 0;
    right: 20%;
}

.landing-body-1-btns{
    display: flex;
    gap: 2em;
}

.house-plant{
    display: none;
}

@media (max-width: 768px) {
    .landing-body-1{
        background-image: unset;
        height: unset;
    }
    
    .landing-body-1-section{
        height: unset;
        flex-direction: column;
        gap: 30px;
    }
    
    .landing-body-1-content{
        width: 100%;
        padding: 5%;
    }
    
    .landing-body-1-img{
        width: 110%;
        text-align: center;
        position: relative;
    }

    .landing-body-1-img img{
        width: 100%;
        height: auto;
        position: unset;
        bottom: unset;
        right: unset;
        border-radius: 15px;
    }

    .house-plant{
        display: unset;
    }
    
}

@media (max-width: 350px) {
    .landing-body-1-btns{
        flex-direction: column;
    }
}