.manual{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.manual h4{
    padding: 0;
    margin: 0;
}

.manual-tap{
    height: 100px;
    margin-top: 3%;
    margin-bottom: 3%;
}

.manual img{
    height: 100%;
}