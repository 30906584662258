.dashboard-automate{
    background-color: #FFFFFF;
    grid-area: automate;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auto-triangle{
    margin-bottom: 5%;
}

.auto-status{
    flex: 1;
    width: 100%;
}