
.userAuthentication{
    width: 100%;
    height: 100%;
}

.userAuth-sections{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.userAuth-section-2{
    width: 60%;
    height: 100%;
    background-color: #E6F0DC;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.userAuth-logo{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.userAuth-logo-img{
    width: 100%;
    height: auto;
    cursor: pointer;
}

.userAuth-logo-txt{
    font-weight: normal;
    font-size: 48px;
    color: #55883B;
    text-align: center;
    margin-bottom: 0;
}

.userAuth-section-2-btns{
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin-top: 2%;
}

@media (max-width: 768px) {
    .userAuth-sections{
        flex-direction: column;
    }   

    .userAuth-section-2{
        width: 100%;
        height: 80%;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 450px) {
    .userAuth-section-2{
        height: 70%;
    }

    .userAuth-logo-txt{
        font-size: 32px;
    }
}