.dashboard-menu{
    background-color: #FFFFFF;
    grid-area: menu;
    display: flex;
    flex-direction: column;
}

.menu-txt{
    font-weight: bold;
    color: #55883B;
    text-align: center;
}

.devices{
    margin-top: 5%;
    margin-bottom: 5%;
    flex-grow: 1;
    overflow-y: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}

.devices::-webkit-scrollbar {
    display: none;
}

.device-list{
    text-align: center;
    padding: 0;
    margin: 0;
}

.device-list li{
    list-style: none;
    text-decoration: none;
    list-style-type: none;
    
}

.device-line h4{
    margin: 0;
    padding: 0;
}

.menu-options{
    display: flex;
    flex-direction: row;
    gap: 5%;
    width: 90%;
    margin-left: 5%;
    align-items: center;
}

.add-device{
    cursor: pointer;
    height: 70%;
}