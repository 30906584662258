.password-form {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 90%; 
    margin-left: 5%;
    gap: 5%;
}

.password-form h1{
    margin: 2%;
}

.password-form p{
    width: 100%;
    margin: 2%;
    padding: 0;
}

.password-form-logo-btns{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2%;
}