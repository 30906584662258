.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: loading;
    height: 100%;
    width: 100%;
}

.loading-dots div {
    display: inline-block;
    width: 5vw;
    height: 5vw;
    margin: 0 2px;
    background-color: #C0C0C0;
    border-radius: 50%;
    animation: loadingDots 1.2s infinite ease-in-out both;
}

.loading-dots div:nth-child(1) {
    animation-delay: -0.24s;
}

.loading-dots div:nth-child(2) {
    animation-delay: -0.12s;
}

.loading-dots div:nth-child(3) {
    animation-delay: 0;
}

@keyframes loadingDots {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}