.modal{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(230, 240, 220, 0.7);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content{
    background-color: #FFFFFF;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    position: relative;
}

.modal-close{
    top: 10%;
    right: 5%;
    cursor: pointer;
    position: absolute;
}


.modal-close img{
    width: 40px;
    height: 40px;
}

.modal-time{
    height: 80px;
}

.modal-time img{
    height: 100%;
    width: auto;
}

.modal-check{
    height: 80px;
}

.modal-check img{
    height: 100%;
    width: auto;
}

.modal-content h4{
    color: #000000;
    font-weight: bold;
}

.modal-content p{
    flex-grow: 1;
    text-align: center;
    width: 60%;
    margin-left: 20%;
}

.modal-btns{
    width: 90%;
    display: flex;
    justify-content: right;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
}

@media (max-width: 768px) {
    .modal-content{
        width: 60%;
        height: 50%;
    }
}

@media (max-width: 500px) {
    .modal-content{
        width: 80%;
        height: 50%;
    }
}