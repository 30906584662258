.landing-body-2{
    width: 100%;
    height: 36%;
    margin-top: 2%;
    position: relative;
}

.file-box{
    margin-left: 5%;
    width: 90%;
    height: 100%;
    background-image: linear-gradient(to bottom, #55883B,#55883B, rgba(33,53,23,0.05));
    border-top-right-radius: 30px;
}

.landing-card-sections{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 5%;
    height: 70%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
}

@media (max-width: 768px) {
    .landing-body-2{
        height: auto;
    }
    
    .file-box{
        margin-left: 5%;
        width: 90%;
        height: auto;
        background-image: linear-gradient(to bottom, #55883B,#55883B, rgba(33,53,23,0.05));
        border-top-right-radius: 30px;
    }
    
    .landing-card-sections{
        padding-top: 30px;
        flex-direction: column;
        justify-content: unset;
        position: relative;
        width: 90%;
        gap: 30px;
        padding-bottom: 30px;
    }
}