.new-device-section-2{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.new-device-section-2-btns{
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin-top: 2%;
}

.new-device-logo-txt{
    font-weight: normal;
    font-size: 30px;
    color: #C0C0C0;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 768px) {
    .new-device-section-2{
        padding-top: 10%;
        padding-bottom: 10%;
        gap: 2%;
    }
}