
.dashboard-header{
    background-color: #FFFFFF;
    grid-area: header;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
}

.dashboard-header-logo{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    gap: 1em;
}

.dashboard-header-logo h1{
    font-weight: normal;
    font-size: 48px;
    color: #55883B;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.dashboard-header-links{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 2em;
}

.dashboard-header-links li {
    list-style: none;
}

.gear{
    height: 40px;
    cursor: pointer;
}

.exit{
    height: 40px;
    cursor: pointer;
}

.refresh{
    height: 40px;
    cursor: pointer;
}

.last-refresh{
    color: #C0C0C0;
    font-weight: bold;
}

@media (max-width: 768px) {

    .dashboard-header-logo h1{
        display: unset;
    }

    .gear{
        height: 30px;
    }
    
    .exit{
        height: 30px;
    }

    .refresh{
        height: 30px;
    }
}

@media (max-width: 530px) {
    .dashboard-header-links{
        flex-direction: column;
    } 
}