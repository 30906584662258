.dashboard-status{
    background-color: #FFFFFF;
    grid-area: status;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.status-indicator{
    flex-grow: 1;
    background-image: url("../../../../Images/water.svg");
    
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    margin-bottom: 10%;
    margin-left: 5%;
    margin-right: 5%;
}

.good-water{
    font-weight: bold;
    color: #0C9B05;
}

.bad-water{
    font-weight: bold;
    color: #E81B00;
}
