html,body {
    font-family: 'Quicksand', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100vw;
    height: 100vh;
}

.App{
    width: 100vw;
    height: 100vh;
}

h1{
    font-size: 34px;
    font-weight: normal;
}

h2{
    font-size: 30px;
    font-weight: normal;
}

h3{
    font-size: 24px;
    font-weight: normal;
}

h4{
    font-size: 20px;
    font-weight: normal;
}

p{
    font-size: 18px;
    font-weight: normal;
}

.padded{
    padding-top: 5%;
}

.hidden{
    visibility: hidden;
}

.grow {
    transition: all 0.2s ease-in-out;
}
  
.grow:hover {
    transform: scale(1.1); 
}

.error-message{
    color: red !important;
    margin: 0;
    padding-top: 1%;
}

.flip-image {
    animation: flip 3s infinite;
}

@keyframes flip {
    0% {
        transform: rotateX(0deg);
    }
    50% {
        transform: rotateX(180deg);
    }
    100% {
        transform: rotateX(360deg);
    }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@keyframes growAndPop {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.loading{
    animation: rotate 3s linear infinite;
}

.loaded {
    animation: growAndPop 0.7s ease-in-out;
}

@media (max-width: 768px) {
    html,body {
        overflow-y: auto;
    }
}

.curser{
    cursor: pointer;
}